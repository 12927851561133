import React from "react"
import { graphql } from "gatsby"
import { allRoutes } from "../helpers/routes"
import Layout from "../components/layout"
import LinkBtn from "../components/linkBtn"
import Img from "gatsby-image"

const FourOhFourPage = ({ data }) => {
  return (
    <Layout mainClass="home">
      <div className="hero">
        <div className="hero__image hero__image--directory">
          <Img fluid={data.exhibitHall2.childImageSharp.fluid} alt="Exhibit hall" />
        </div>
        <header className="hero__message">
          <h1>404 booth not found</h1>
        </header>
      </div>
      <div className="content-area four-oh-four">
        <p>We're sorry, but this booth can't be found. Please head back to the exhibit hall and try again.</p>
        <LinkBtn className="btn" to={allRoutes.directory}>
          Back to the Directory
        </LinkBtn>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query FourOhFourPage {
    exhibitHall2: file(relativePath: { eq: "exhibit-hall-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default FourOhFourPage